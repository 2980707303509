<template>
  <modal name="delete-tree-modal" classes="clear_modal delete-tree-modal" :scrollable="true" height="auto">
    <div class="modal-content">
      <close-button @click="closeModal"></close-button>
      <h4 class="title">
        Are you sure you want to delete <span class="highlighted">{{ treeName }}</span
        >?
      </h4>
      <p>
        All {{ personsCount }} persons and {{ assetsCount }} photos and files associated with this tree will also be
        deleted.
      </p>
      <p>
        To delete this tree, type "<b>{{ treeName }}</b
        >" into the input below
      </p>
      <div class="input-container">
        <input @input="changeInputValue" />
      </div>
      <div class="buttons">
        <mcr-button class="cancel" :disabled="loading" @click="closeModal">Cancel</mcr-button>
        <mcr-button class="delete-button" :disabled="isDeleteDisabled" :loading="loading" @click="deleteTree"
          >Delete</mcr-button
        >
      </div>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import McrButton from '@common/elements/buttons/mcrButton';

export default {
  props: {
    treeName: String,
    treeId: Number,
    personsCount: Number,
    assetsCount: Number,
  },
  data() {
    return {
      inputValue: '',
      loading: false,
    };
  },
  computed: {
    isDeleteDisabled() {
      return this.inputValue !== this.treeName;
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide('delete-tree-modal');
    },
    changeInputValue(event) {
      this.inputValue = event.target.value;
    },
    deleteTree() {
      this.loading = true;
      this.$store
        .dispatch('deleteFamilyTreeAction', {id: this.treeId})
        .then(response => {
          this.$toasted.success(`${this.treeName} was deleted.`);
          if (this.$store.getters.activeFamilyTreeIdState == this.treeId) {
            this.$store.commit('clearActiveFamilyTreeIdState');
          }
          if (response.member_family_tree_id) {
            this.$store.commit('setUserFamilyTreeIdState', response.member_family_tree_id);
          }
          this.$router.push({name: 'familytree-manage-all'});
        })
        .catch(() => {
          this.$toasted.error('Something went wrong during tree deletion. Please try again later.');
          this.loading = false;
        });
    },
  },
  components: {CloseButton, McrButton},
  name: 'DeleteTreeModal',
};
</script>

<style lang="scss" scoped>
.delete-tree-modal {
  .modal-content {
    .title {
      margin-top: 20px;
    }
    .input-container {
      input {
        width: 100%;
      }
    }
    .highlighted {
      color: $mcr-red;
    }

    .delete-button {
      min-width: 150px;
    }
  }
}
</style>
