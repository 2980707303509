<template>
  <div class="clipboard-input tree-share-link">
    <copy-icon class="copy-icon" :size="22"></copy-icon>
    <input
      class="family-tree-link input"
      ref="link"
      readonly
      :value="familyTreeHref"
      v-clipboard:copy="familyTreeHref"
      v-clipboard:success="copyLinkSuccess"
    />
    <transition name="fade">
      <div v-if="displayCopiedSuccess" class="copy-success">Copied!</div>
    </transition>
  </div>
</template>

<script>
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import CopyIcon from 'vue-material-design-icons/ContentCopy';

export default {
  props: {
    treeId: [String, Number],
  },
  data() {
    return {
      displayCopiedSuccess: false,
    };
  },
  computed: {
    familyTreeHref() {
      let resolved = this.$router.resolve(this.familyTreeRoute);
      return `${window.location.host}${resolved.href}`;
    },
    familyTreeRoute() {
      return {name: 'familytree-details', params: {id: this.treeId}};
    },
  },
  methods: {
    copyLinkSuccess() {
      this.$refs.link.select();
      AnalyticsAmplitudeHandler.trackClickShareLinkEvent(getRoutePageName(this.$route));
      this.displayCopiedSuccess = true;
      setTimeout(() => {
        this.displayCopiedSuccess = false;
      }, 1000);
    },
  },
  components: {
    CopyIcon,
  },
  name: 'TreeShareLink',
};
</script>

<style lang="scss" scoped>
.tree-share-link {
  position: relative;
  width: 100%;
  .copy-icon {
    position: absolute;
    right: 11px;
    top: 11px;
  }
  .copy-success {
    position: absolute;
    border-radius: 4px;
    right: 7px;
    top: 6px;
    padding: 4px;
    background-color: $success-color;
    color: $off-white;
  }

  .family-tree-link {
    width: 100%;
    cursor: pointer;
  }
}
</style>
