<template>
  <delete-confirm-modal-content :modal-name="modalName" :loading="loading" @confirm="deleteUserRole">
    <h5>
      Are you sure you want to remove <span class="highlight">{{ email }}</span> from collaborators?
    </h5>
  </delete-confirm-modal-content>
</template>

<script>
import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

export default {
  props: {
    email: String,
    modalName: String,
    deleteUserRole: Function,
  },
  computed: {
    loading() {
      return this.$store.getters.familyTreeDeleteMemberLoadingState[this.email];
    },
  },
  components: {DeleteConfirmModalContent},
  name: 'TreeMemberRoleDeleteConfirm',
};
</script>

<style lang="scss" scoped></style>
