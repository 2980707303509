<template>
  <div class="manage-tree-page" v-if="familyTreeDetailsLoadingState">
    <div class="readable_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
  <div class="manage-tree-page" v-else-if="loadingError">
    <page-not-found></page-not-found>
  </div>
  <div class="manage-tree-page" v-else>
    <div class="limited_content heading-content">
      <router-link :to="manageTreesRoute" class="breadcrumb-link">‹ Manage Trees</router-link>
      <div class="tree-meta">
        <h4 class="main-heading">{{ familyTreeDetailsState.name }}</h4>
        <div class="meta-details">
          <div class="meta-item text-sm">
            <profile-icon :size="18" title="" />
            {{ familyTreeDetailsState.persons_count }}
            {{ familyTreeDetailsState.persons_count > 1 ? 'Persons' : 'Person' }}
          </div>
          <div class="meta-item text-sm">
            <assets-icon :size="20" title="" />
            {{ familyTreeDetailsState.assets_count }} Media & Files
          </div>
          <div class="meta-item text-sm" v-if="isDefaultTree">
            <home-icon class="home-icon" :size="20" title="" />
            Default Tree
          </div>
          <div class="meta-item text-sm" v-if="isEditor">
            <editor-icon class="home-icon" :size="20" title="" />
            Editor Access
          </div>
          <div class="meta-item text-sm" v-if="isViewer">
            <viewer-icon class="home-icon" :size="20" title="" />
            Viewer Access
          </div>

          <mcr-button-router-link class="white small view-tree" :to="ftRoute"
            ><tree-icon class="" :size="20" />View Tree</mcr-button-router-link
          >
        </div>
      </div>
    </div>
    <div class="heading-content-mobile" v-if="!isTreeMenuRoute">
      <router-link :to="manageTreeMenuRoute" class="breadcrumb-link">‹ Back</router-link>
      <mcr-button-router-link class="white small view-tree" :to="ftRoute"
        ><tree-icon class="" :size="20" />View Tree</mcr-button-router-link
      >
    </div>

    <div class="limited_content mobile_full_width main-content" v-if="familyTreeDetailsState.object_id">
      <settings-navigation></settings-navigation>
      <router-view class="setting-content"></router-view>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import ProfileIcon from 'vue-material-design-icons/Account';
import ViewerIcon from 'vue-material-design-icons/Eye';
import HomeIcon from 'vue-material-design-icons/Home';
import AssetsIcon from 'vue-material-design-icons/Image';
import EditorIcon from 'vue-material-design-icons/Pencil';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

import SettingsNavigation from '@/components/modules/familyTreeManage/manageTree/SettingsNavigation';
import PageNotFound from '@/components/page.not.found';

export default {
  metaInfo() {
    const treeName = this.familyTreeDetailsState.name;
    return {
      title: treeName ? `Manage Tree - ${treeName}` : 'Manage Tree',
    };
  },
  created() {
    this.$store
      .dispatch('fetchFamilyTreeDetailsAction', this.treeId)
      .then(response => {
        this.newName = response.name;
        this.selectedHomePerson = {
          object_id: response.home_person.id,
          full_name: response.home_person.full_name || 'Unknown name',
        };
      })
      .catch(() => {
        this.loadingError = true;
      });
  },
  data() {
    return {
      loadingError: false,
      newName: '',
      saveNameLoading: false,
      selectedHomePerson: {},
      saveHomePersonLoading: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreeDetailsState', 'familyTreeDetailsLoadingState', 'previousRouteState']),
    ftRoute() {
      return {name: 'familytree-details', params: {id: this.familyTreeDetailsState.object_id}};
    },
    manageTreesRoute() {
      return {name: 'familytree-manage-all'};
    },
    manageTreeMenuRoute() {
      return {name: 'familytree-manage', params: {id: this.familyTreeDetailsState.object_id}};
    },
    isTreeMenuRoute() {
      return this.$route.name === 'familytree-manage';
    },
    treeId() {
      return this.$route.params.id;
    },
    isDefaultTree() {
      return this.familyTreeDetailsState.object_id === this.$store.getters.userFamilyTreeIdState;
    },
    isEditor() {
      return !this.familyTreeDetailsState.is_owner && this.familyTreeDetailsState.is_editor;
    },
    isViewer() {
      return !this.familyTreeDetailsState.is_owner && !this.familyTreeDetailsState.is_editor;
    },
  },
  name: 'ManageTreePage',
  components: {
    McrButtonRouterLink,
    PageNotFound,
    ProfileIcon,
    HomeIcon,
    AssetsIcon,
    TreeIcon,
    SettingsNavigation,
    EditorIcon,
    ViewerIcon,
  },
};
</script>

<style lang="scss" scoped>
.manage-tree-page {
  .breadcrumb-link {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 8px;

    .material-design-icon {
      margin-right: 5px;
    }
  }
  .heading-content {
    margin-bottom: 20px;
    padding-bottom: 0;
    .main-heading {
      font-weight: 900;
      margin: 0 20px 0 0;
    }
  }
  .heading-content-mobile {
    display: none;
  }

  .tree-meta {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .meta-details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .meta-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: $neutral-600;

      .material-design-icon {
        margin-right: 3px;
        margin-top: -2px;
      }
    }
  }

  .view-tree {
    margin-left: auto;
    .material-design-icon {
      margin-right: 10px;
    }
  }

  .main-content {
    display: flex;
    align-items: flex-start;
    padding-top: 0;

    .settings-navigation {
      margin-right: 32px;

      &::v-deep {
        .arrow-icon {
          display: none;
        }
      }
    }

    .setting-content {
      flex-grow: 1;
    }

    &::v-deep {
      .settings-item {
        .heading-6 {
          margin-bottom: 15px;
        }
        .heading-6.no-margin {
          margin-bottom: 0;
        }

        .label {
          margin-bottom: 8px;
          color: $neutral-400;
        }

        .full-width {
          width: 100%;
        }

        .action-buttons {
          display: flex;
          margin-top: 20px;
          justify-content: flex-end;

          .save-button {
            min-width: 150px;
          }
        }

        .inline-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        + .settings-item {
          margin-top: 32px;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .breadcrumb-link {
      margin-bottom: 0;
    }
    .heading-content {
      display: none;
    }
    .heading-content-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px;
    }
    .settings-navigation {
      display: none;
    }
  }
}
</style>
