<template>
  <div class="switch-toggle" @click="toggleCheckbox">
    <input :checked="value" type="checkbox" class="switch-toggle__input" />
    <div class="slider round"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  methods: {
    toggleCheckbox() {
      this.$emit('toggle', !this.value);
    },
  },
  name: 'SwitchToggle',
};
</script>

<style lang="scss" scoped>
.switch-toggle {
  position: relative;
  display: inline-block;
  width: 66px;
  height: 36px;

  .switch-toggle__input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $photo-border-color;
    border: 1px solid $input-border-color;
    box-shadow: $box-shadow-light;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 34px;
    width: 34px;
    left: 0;
    top: -1px;
    background-color: white;
    border: 1px solid $input-border-color;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  input:checked + .slider {
    background-color: $success-color;
    border: 1px solid $success-color;
  }

  input:checked + .slider:before {
    border-color: $success-color;
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }

  .slider.round {
    border-radius: 35px;
  }

  .slider.round:before {
    border-radius: 35px;
  }
}
</style>
