<template>
  <div class="mage-tree-mobile-menu-page">
    <div class="heading-content">
      <router-link :to="manageTreesRoute" class="breadcrumb-link">‹ Manage Trees</router-link>
      <h5 class="main-heading heading-5 meta-item">{{ familyTreeDetailsState.name }}</h5>
    </div>
    <stand-out-block class="tree-meta-block light">
      <div class="tree-meta">
        <div class="meta-item">
          <div class="count heading-6">{{ familyTreeDetailsState.persons_count }}</div>
          <div class="text-md">{{ familyTreeDetailsState.persons_count > 1 ? 'Persons' : 'Person' }}</div>
        </div>
        <div class="meta-item">
          <div class="count heading-6">{{ familyTreeDetailsState.assets_count }}</div>
          <div class="text-md">Media & Files</div>
        </div>
        <div class="meta-item" v-if="isDefaultTree">
          <div class="icon-container"><home-icon class="home-icon" :size="24" title="" /></div>
          <div class="text-md">Default Tree</div>
        </div>
        <div class="meta-item" v-if="isEditor">
          <div class="icon-container"><editor-icon class="home-icon" :size="20" title="" /></div>
          <div class="text-md">Editor Access</div>
        </div>
        <div class="meta-item" v-if="isViewer">
          <div class="icon-container"><viewer-icon class="home-icon" :size="20" title="" /></div>
          <div class="text-md">Viewer Access</div>
        </div>
      </div>
      <mcr-button-router-link class="white small view-tree" :to="ftRoute"
        ><tree-icon class="" :size="20" />View Tree</mcr-button-router-link
      >
    </stand-out-block>
    <div class="label">Settings</div>
    <settings-navigation></settings-navigation>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import ViewerIcon from 'vue-material-design-icons/Eye';
import HomeIcon from 'vue-material-design-icons/Home';
import EditorIcon from 'vue-material-design-icons/Pencil';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

import SettingsNavigation from '@/components/modules/familyTreeManage/manageTree/SettingsNavigation';

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters.windowWidthState > vm.$breakpoints.mobile) {
        vm.$router.replace({name: 'familytree-manage-details', params: {id: vm.$route.params.id}});
        return false;
      }
    });
  },
  computed: {
    ...mapGetters(['familyTreeDetailsState']),
    ftRoute() {
      return {name: 'familytree-details', params: {id: this.familyTreeDetailsState.object_id}};
    },
    manageTreesRoute() {
      return {name: 'familytree-manage-all'};
    },
    isDefaultTree() {
      return this.familyTreeDetailsState.object_id === this.$store.getters.userFamilyTreeIdState;
    },
    isEditor() {
      return !this.familyTreeDetailsState.is_owner && this.familyTreeDetailsState.is_editor;
    },
    isViewer() {
      return !this.familyTreeDetailsState.is_owner && !this.familyTreeDetailsState.is_editor;
    },
  },
  components: {SettingsNavigation, HomeIcon, McrButtonRouterLink, TreeIcon, StandOutBlock, EditorIcon, ViewerIcon},
  name: 'ManageTreeMobileMenu',
};
</script>

<style lang="scss" scoped>
.mage-tree-mobile-menu-page {
  .heading-content {
    margin: 20px;
    .main-heading {
      margin: 10px 0;
    }
  }
  .label {
    margin-left: 10px;
    margin-bottom: 5px;
    color: $neutral-400;
  }
  .settings-navigation::v-deep {
    padding: 0;
    border-radius: 0;
    .settings-navigation-item {
      padding: 14px;
      margin: 0;
      border-radius: 0;
    }

    .settings-navigation-item:not(:last-of-type) {
      border-bottom: 1px solid $divider-line-alternative-color;
    }
  }

  .view-tree {
    margin-top: 20px;
    .material-design-icon {
      margin-right: 10px;
    }
  }

  .tree-meta-block {
    padding: 16px;
    margin-bottom: 20px;
    .tree-meta {
      display: flex;
      justify-content: space-around;
      .meta-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .count {
          color: $neutral-500;
          font-weight: bold;
        }
        .text-md {
          color: $neutral-600;
        }
      }
      .icon-container {
        height: 24px;
      }
      .home-icon {
        color: $neutral-500;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    display: none;
  }
}
</style>
