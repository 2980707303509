<template>
  <div class="tree-member-role-select">
    <multiselect
      class="bordered roles-select medium-size"
      :value="value"
      placeholder=""
      :searchable="false"
      :internal-search="false"
      :preserveSearch="false"
      :clearOnSelect="false"
      :show-no-options="true"
      :hide-selected="false"
      :options="roleOptions"
      track-by="value"
      label="name"
      :showLabels="false"
      @select="onRoleValueChange"
    >
      <template v-slot:option="params">
        <span class="option-label" :class="{red: params.option.isRemove}">
          {{ params.option.name }}
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import TreeMemberRoleDeleteConfirm from '@/components/common/tree/TreeMemberRoleDeleteConfirm';

const VIEWER_ROLE = 'viewer';
const EDITOR_ROLE = 'editor';
const REMOVE_ROLE = 'remove';

export default {
  props: {
    treeId: [String, Number],
    email: String,
    isEditor: Boolean,
  },
  data() {
    return {
      confirmDeleteUserRoleModalName: `confirm-delete-user-role-${this.email}`,
    };
  },
  computed: {
    value() {
      const role = this.isEditor ? EDITOR_ROLE : VIEWER_ROLE;
      return this.roleOptions.find(item => item.value === role) || {};
    },
    roleOptions() {
      return [
        {value: VIEWER_ROLE, name: 'Viewer'},
        {value: EDITOR_ROLE, name: 'Editor'},
        {value: REMOVE_ROLE, name: 'Remove', isRemove: true},
      ];
    },
  },
  methods: {
    onRoleValueChange(role) {
      if (role.value === VIEWER_ROLE || role.value === EDITOR_ROLE) {
        return this.changeUserRole(role);
      }
      if (role.value === REMOVE_ROLE) {
        return this.openConfirmDeleteUserRole(role);
      }
    },
    changeUserRole(role) {
      const isEditor = role.value === EDITOR_ROLE;
      this.$emit('pre-role-change', {email: this.email, is_editor: isEditor});
      return this.$store
        .dispatch('familyTreeEditMemberAction', {
          id: this.treeId,
          email: this.email,
          is_editor: isEditor,
        })
        .then(() => {
          this.$toasted.success('User role updated successfully!');
        })
        .catch(() => {
          this.$toasted.error('Error updating user role. Changes were not saved.');
        });
    },
    openConfirmDeleteUserRole(role) {
      let modalParams = {classes: 'clear_modal', name: this.confirmDeleteUserRoleModalName};
      const props = {
        email: this.email,
        modalName: this.confirmDeleteUserRoleModalName,
        deleteUserRole: this.deleteUserRole,
      };
      this.$modal.show(TreeMemberRoleDeleteConfirm, props, modalParams);
    },
    deleteUserRole() {
      return this.$store
        .dispatch('familyTreeDeleteMemberAction', {
          id: this.treeId,
          email: this.email,
        })
        .then(() => {
          this.$toasted.success('User removed from collaborators!');
          this.$modal.hide(this.confirmDeleteUserRoleModalName);
          this.$emit('deleted', {email: this.email});
        })
        .catch(() => {
          this.$toasted.error('Error removing user role. Changes were not saved.');
        });
    },
  },
  components: {Multiselect},
  name: 'TreeMemberRoleSelect',
};
</script>

<style lang="scss" scoped>
.tree-member-role-select {
  .roles-select {
    min-width: 100px;

    &::v-deep {
      .multiselect__tags {
        cursor: pointer;
      }
      .multiselect__option:not(.multiselect__option--highlight) .option-label.red {
        color: $rose-600;
      }
    }
  }
}
</style>
